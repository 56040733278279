//BRAND
export const name = 'PickMyCourse';
export const company = 'SeenIT';
export const websiteURL = 'http://skinsmoothies';
export const serverURL = 'https://pmcv2.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/pickmycourse-ce045.appspot.com/o/PMC_logos.png?alt=media&token=a752747c-e216-48df-ba6e-e8524f3680bf';
export const razorpayEnabled = false;
export const paypalEnabled = false;
export const stripeEnabled = true;
export const paystackEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 9;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 99;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Dinesh Kumar";
export const profession = 'Software Developer';
export const photoURL = 'https://media.licdn.com/dms/image/D5603AQEDjPydFO9Z2A/profile-displayphoto-shrink_200_200/0/1683617907233?e=1726704000&v=beta&t=VqAmZII4To8buI_Zj6t-Z3JMuv0i2iFyCSdfB1qeEpU';

// //PAYPAL
export const paypalPlanIdOne = "";
export const paypalPlanIdTwo = "";

// //RAZORPAY
export const razorpayKeyId = "";
export const razorpayPlanIdOne = "";
export const razorpayPlanIdTwo = "";

//STRIPE
export const stripePlanIdOne = "price_1Pd8mq01PbsRdqnLHOa9bhU1";
export const stripePlanIdTwo = "";

//PAYSTACK
export const paystackPlanIdOne = "";
export const paystackPlanIdTwo = "";
export const amountInZarOne = '';
export const amountInZarTwo = '';